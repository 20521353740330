import moment from "moment";
import _ from "lodash";

export function isExisting(transaction) {
  return !!transaction?.id;
}
export function isDated(transaction) {
  return !!transaction?.date;
}
export function isClosed(transaction) {
  return transaction?.state === "closed";
}
export function hasState(transaction) {
  return !!transaction?.state;
}
export function awardable(transaction) {
  return _.includes(
    ["under_contract", "withdrawn"],
    _.get(transaction, "state"),
  );
}
export function unknownSupplySide(transaction) {
  return (
    isExisting(transaction) && (!isDated(transaction) || !hasState(transaction))
  );
}
export function mustBeClosed(transaction) {
  const investmentTypeCheck = transaction?.investmentType
    ? transaction.investmentType === "OwnershipInterest"
    : true;
  return (
    isExisting(transaction) &&
    isDated(transaction) &&
    isClosed(transaction) &&
    investmentTypeCheck
  );
}
export function fetchMilliseconds(transaction, asOfMilliseconds) {
  switch (transaction?.recordType) {
    case "Investment": {
      return transaction?.date
        ? moment(transaction?.date).valueOf()
        : asOfMilliseconds;
    }
    default: {
      switch (transaction?.state) {
        case "withdrawn":
        case "closed": {
          return transaction?.date
            ? moment(transaction?.date).valueOf()
            : asOfMilliseconds;
        }
        default:
          return asOfMilliseconds;
      }
    }
  }
}
export function existingContentId({ containerObject, transaction }) {
  return (
    transaction?.assetId ||
    transaction?.spaceId ||
    _.get(containerObject, "dataField.fieldContentId") ||
    transaction?.space?.fieldContentId
  );
}
export function existingContentType({ containerObject, transaction }) {
  return (
    transaction?.assetType ||
    transaction?.spaceType ||
    _.get(containerObject, "dataField.fieldContentType") ||
    transaction?.space?.fieldContentType
  );
}
function isInvestment(transaction) {
  return !!transaction?.investmentType;
}
export function contentId(transaction) {
  if (isInvestment(transaction) && !subjectIsFieldContent(transaction)) {
    return _.get(decoratingContent(transaction), "decoratingContentId");
  } else {
    return _.get(decoratingContent(transaction), "fieldContentId");
  }
}
export function contentType(transaction) {
  if (isInvestment(transaction) && !subjectIsFieldContent(transaction)) {
    return _.get(decoratingContent(transaction), "decoratingContentType");
  } else {
    return _.get(decoratingContent(transaction), "fieldContentType");
  }
}
export function decoratingContent(transaction) {
  return (
    transaction.availabilityFieldContent ||
    transaction.investmentFieldContent ||
    transaction.asset ||
    transaction.space
  );
}
export function subjectIsFieldContent(transaction) {
  return _.includes(
    ["Property"],
    _.get(decoratingContent(transaction), "decoratingContentType"),
  );
}
export function groupId(transaction) {
  return (
    _.get(transaction, "investmentGroupId") ||
    _.get(transaction, "availabilityGroupId") ||
    _.get(transaction, "portfolioId")
  );
}
